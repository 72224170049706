import { Grid, IconButton } from "@mui/material";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { LagerInformation } from "../../../Models/LagerInformation";
import { ArtikelInformation } from "../../../Models/ArtikelInformation";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import CustomGridToolbar from "../../../layout/CustomGridToolbar";
import { Artikel } from "Models/Artikel";

type LagerInformationProps = {
    lager: LagerInformation;
};
const ShowLagerInformation = (props: Readonly<LagerInformationProps>) => {
    const { lager } = props;
    const [visible, setVisible] = useState(false);

    const toggleBestand = () => {
        setVisible(!visible);
    };

    return (
        <>
            <h3>
                <Grid container spacing={0}>
                    <Grid item xs={3}>
                        <IconButton color="primary" onClick={() => toggleBestand()}>
                            {visible ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                        </IconButton>
                        {lager.name} (nr: {lager.nummer} )
                    </Grid>
                    <Grid item xs={9}>
                        {lager?.nummer} {lager.firma?.name}
                    </Grid>
                </Grid>
            </h3>
            <BestandTable bestand={lager.artikelInfos} visible={visible} />
        </>
    );
};

export default ShowLagerInformation;

type BestandTableProps = {
    bestand: ArtikelInformation[];
    visible: boolean;
};
export const BestandTable = (props: Readonly<BestandTableProps>) => {
    const { bestand, visible } = props;
    if (visible && bestand) {
        let cols: GridColDef<ArtikelInformation>[] = [
            {
                field: "artikel",
                headerName: "Artikel",
                width: 100,
                valueGetter: (ainfo: Artikel) => {
                    return ainfo.artikelnummer;
                },
            },
            {
                field: "funktionsklasse",
                headerName: "Funktionsklasse",
                width: 120,
                valueGetter: (a: any, aInfo: ArtikelInformation) => {
                    return aInfo.artikel.funktionsklasse;
                },
            },
            {
                field: "Name",
                headerName: "Name",
                width: 500,
                valueGetter: (a: any, aInfo: ArtikelInformation) => {
                    return aInfo.artikel.name;
                },
            },
            { field: "inBestand", headerName: "Bestand", width: 100, type: "number" },
            { field: "inMontageauftraege", headerName: "in Aufträgen", width: 100, type: "number" },
            { field: "inAnforderungen", headerName: "angefordert", width: 100, type: "number" },
        ];
        return (
            <DataGridPro
                autoHeight
                columns={cols}
                rows={bestand}
                getRowId={(info) => info.artikel.id}
                slots={{
                    toolbar: CustomGridToolbar,
                }}
            />
        );
    } else {
        return <></>;
    }
};
