type DateDisplayProps = {
    date: Date;
    showTime?: boolean;
    showWeek?: boolean;
};
export default function DateDisplay(props: Readonly<DateDisplayProps>) {
    const { date, showTime, showWeek } = props;

    return <>{dateFormat(date, showTime, showWeek)}</>;
}

export function dateFormat(date: Date, showTime?: boolean, showWeek?: boolean): string {
    if (!date) return "-";

    try {
        let dt;
        if (!date.toString().endsWith("Z")) {
            dt = new Date(date + "Z"); // We need to attach UTC timezone manipulator Z!!
        } else {
            dt = new Date(date);
        }

        let result = dt.toLocaleDateString([], { month: "2-digit", day: "2-digit", year: "2-digit" });
        if (showTime) {
            result += " " + dt.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
        }

        if (showWeek) {
            result += " (KW: " + getWeekNumber(dt) + ")";
        }
        return result;
    } catch (error) {
        return "?";
    }
}

/* Set as calculateWeek to determine the week of the year based on the ISO 8601 definition.
 * source: https://github.com/jquery/jquery-ui/blob/1-12-stable/ui/widgets/datepicker.js#L1106-L1121
 * @param  date  Date - the date to get the week for
 * @return  number - the number of the week within the year that contains this date
 */
export function getWeekNumber(date: Date): number {
    const checkDate = new Date(date.getTime());

    // Find Thursday of this week starting on Monday
    checkDate.setDate(checkDate.getDate() + 4 - (checkDate.getDay() || 7));

    const time = checkDate.getTime();
    checkDate.setMonth(0); // Compare with Jan 1
    checkDate.setDate(1);
    return Math.floor(Math.round((time - checkDate.getTime()) / 86400000) / 7) + 1;
}
