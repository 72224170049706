import { AppBar, Box, Button, Toolbar } from "@mui/material";
import useAuthentication from "authentication/useAuthentication";
import { UserRoles } from "Models/User";
import { useMatch } from "react-router-dom";
import UserProfile from "./UserProfile";
import NavigationLogistik from "applications/logistik/NavigationLogistik";
import NavigationBestellung from "applications/bestellung/NavigationBestellung";
import NavigationAdministration from "applications/administration/NavigationAdministration";

export default function Navigation() {
    const { hasRole } = useAuthentication();

    const isLogistikRoute = useMatch("/logistik/*");
    const isAdminRoute = useMatch("/administration/*");

    let appBarColor: "primary" | "secondary" | "error" = "primary";
    if (isLogistikRoute) {
        appBarColor = "secondary";
    } else if (isAdminRoute) {
        appBarColor = "error";
    }

    return (
        <AppBar color={appBarColor} position="static">
            <Toolbar>
                <Box sx={{ flexGrow: 1, display: { md: "flex" } }}>
                    {hasRole(UserRoles.ANFORDERER) && <NavigationBestellung />}
                    {hasRole(UserRoles.KOMMISIONIERER, UserRoles.KOORDINATOR) && <NavigationLogistik />}
                    {hasRole(UserRoles.ADMINISTRATOR, UserRoles.KOORDINATOR) && <NavigationAdministration />}
                </Box>
                <UserProfile />
            </Toolbar>
        </AppBar>
    );
}
