import { AnforderungsFilter, DefaultAnforderungsFilter, NotAssignedFilter, OverDueFilter } from "./AnfoderungsFilter";

export type DefinedAnforderungsFilter = {
    id: number;
    name: string;
    filter: AnforderungsFilter;
};

export const PredefinedAnforderungsFilter: DefinedAnforderungsFilter[] = [
    { id: 0, name: "Standard", filter: DefaultAnforderungsFilter },
    { id: 1, name: "Nicht zugewiesen", filter: NotAssignedFilter },
    { id: 2, name: "Überfällig", filter: OverDueFilter },
];
