import { useEffect, useState } from "react";
import axiosClient from "ApiClient";

export default function useGet<T>(route: string) {
    const [data, setData] = useState<T[] | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    const load = async () => {
        setIsLoading(true);
        setError("");
        setData(undefined);
        await axiosClient
            .get<T[]>(route)
            .then((response) => {
                setData(response.data);
                setIsLoading(false);
            })
            .catch((error) => {
                setError(error.message + " " + error.response?.data);
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        if (!isLoading) {
            load();
        }
    }, []);

    return { data, isLoading, error, load };
}
