//**
/* https://mui.com/material-ui/react-button-group/#split-button
 */
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Button, ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from "@mui/material";
import { useRef, useState } from "react";

export type SplitButtonOptionsProps = {
    name: string;
    disabled?: boolean;
};
type SplitButtonProps = {
    options: SplitButtonOptionsProps[];
    sx?: React.CSSProperties;
    variant?: "text" | "outlined" | "contained";
    size?: "small" | "medium" | "large";
    onClick?: (index: number) => void;
    onSelectedChanged?: (index: number) => void;
};
export default function SplitButton(props: Readonly<SplitButtonProps>) {
    const { options, sx, size, variant, onClick, onSelectedChanged } = props;
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
        if (index !== selectedIndex) {
            setSelectedIndex(index);
            if (onSelectedChanged) {
                onSelectedChanged(index);
            }
        }
        setOpen(false);
    };
    const handleButtonClick = () => {
        if (onClick) {
            onClick(selectedIndex);
        }
    };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (anchorRef.current?.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };

    return (
        <>
            <ButtonGroup ref={anchorRef} variant={variant ?? "outlined"} size={size} sx={[{ height: "100%", width: "100%" }, sx ?? {}]}>
                <Button onClick={handleButtonClick} sx={{ maxHeight: "100%", flex: 1, minWidth: "100%", textWrap: "nowrap", overflow: "none", textOverflow: "ellipsis" }}>
                    {options[selectedIndex].name}
                </Button>
                <Button size="small" onClick={handleToggle} sx={{ maxHeight: "100%" }}>
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Popper
                sx={{ zIndex: 2, width: anchorRef.current?.clientWidth }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                placement="bottom-start"
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                        }}
                    >
                        <Paper sx={{ boarder: 1 }} elevation={8}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {options.map((option, index) => (
                                        <MenuItem
                                            key={option.name}
                                            disabled={option.disabled}
                                            selected={index === selectedIndex}
                                            onClick={(event) => handleMenuItemClick(event, index)}
                                        >
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
}
