import useAuthentication from "../authentication/useAuthentication";
import useGetBlob from "../applications/hooks/useGetBlob";
import { AccountCircle } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { Avatar } from "@mui/material";

export default function UserAvatar() {
    const { isInternalUser } = useAuthentication();

    const { data, load } = useGetBlob("angelo/me/avatar");
    const [imgData, setImgData] = useState<string>();

    useEffect(() => {
        if (isInternalUser) {
            load();
        }
    }, [isInternalUser]);

    useEffect(() => {
        if (isInternalUser) {
            setImgData(data?.toString());
        }
    }, [data]);

    return (
        <>
            {isInternalUser && imgData && <Avatar alt="Avatar" src={imgData} />}
            {!imgData && <AccountCircle />}
        </>
    );
}
