import { ImportStatus } from "Models/InventoryImport";
import CheckIcon from "@mui/icons-material/Check";
import LoopIcon from "@mui/icons-material/Loop";
import ErrorIcon from "@mui/icons-material/Error";
import { Box } from "@mui/material";

type ImportStatusDisplayProps = {
    status: ImportStatus;
};
export default function ImportStatusDisplay(props: Readonly<ImportStatusDisplayProps>) {
    const { status } = props;

    let icon;

    switch (status) {
        case ImportStatus.SUCCESS:
            icon = <CheckIcon fontSize="small" color="success" />;
            break;
        case ImportStatus.INPROGRESS:
            icon = <LoopIcon fontSize="small" color="primary" />;
            break;
        case ImportStatus.FAILURE:
            icon = <ErrorIcon fontSize="small" color="error" />;
            break;
    }

    return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
            {icon} {getImportStatusAsText(status)}
        </Box>
    );
}

export function getImportStatusAsText(status: ImportStatus): string {
    switch (status) {
        case ImportStatus.SUCCESS:
            return "OK";
        case ImportStatus.INPROGRESS:
            return "importiere...";
        case ImportStatus.FAILURE:
        default:
            return "Fehler";
    }
}
