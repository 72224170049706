import { useState } from "react";
import axiosClient from "../../ApiClient";

export default function useGetDynamicPath<T>(route: string) {
    const [data, setData] = useState<T[] | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [baseUrl] = useState<string>(route);

    const reset = () => {
        setIsLoading(true);
        setError("");
        setData(undefined);
    };

    const load = async (params: Array<{ key: string; value: string }>) => {
        if (params.length === 0) {
            setError("Parameters cannot be empty");
            return;
        }
        reset();
        let url = baseUrl;
        params.forEach((r) => {
            url = url.replace("{" + r.key + "}", r.value);
        });
        await axiosClient
            .get<T[]>(url)
            .then((response) => {
                setData(response.data);
                setIsLoading(false);
            })
            .catch((error) => {
                setError(error.message + " " + error.response?.data);
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return { data, isLoading, error, load };
}
