import { AnforderungsTyp, BearbeitungsStatus } from "Models/Anforderung";

export type AnforderungsFilter = {
    assignedTo: string[];
    dueDate: Date | null;
    dueDateCondition: DateConditiong;
    lager: string[];
    orderDate: Date | null;
    orderDateCondition: DateConditiong;
    searchphrase: string;
    status: BearbeitungsStatus[];
    typ: AnforderungsTyp[];
    history?: boolean;
};
export type DateConditiong = "lt" | "gt" | "eq";

export const DefaultAnforderungsFilter: AnforderungsFilter = {
    searchphrase: "",
    status: [],
    typ: [],
    lager: [],
    assignedTo: [""],
    orderDate: null,
    dueDate: null,
    orderDateCondition: "eq",
    dueDateCondition: "eq",
};

//Get used in Anforderer Bestellhistorie and Historie at Lager
export const HistoryAnforderungsFilter: AnforderungsFilter = {
    searchphrase: "",
    status: [BearbeitungsStatus.Abgeschlossen],
    typ: [],
    lager: [],
    assignedTo: [""],
    orderDate: null,
    dueDate: null,
    orderDateCondition: "eq",
    dueDateCondition: "eq",
    history: true,
};

//Get used in Anforderer Offene Bestellungen
export const OffeneBestellAnforderungsFilter: AnforderungsFilter = {
    searchphrase: "",
    status: [BearbeitungsStatus.InKommissionierung, BearbeitungsStatus.Offen, BearbeitungsStatus.Teillieferung, BearbeitungsStatus.Versendet],
    typ: [],
    lager: [],
    assignedTo: [""],
    orderDate: null,
    dueDate: null,
    orderDateCondition: "eq",
    dueDateCondition: "eq",
};

export const NotAssignedFilter: AnforderungsFilter = {
    searchphrase: "",
    status: [],
    typ: [],
    lager: [],
    assignedTo: ["0"],
    orderDate: null,
    dueDate: null,
    orderDateCondition: "eq",
    dueDateCondition: "eq",
};

export const OverDueFilter: AnforderungsFilter = {
    searchphrase: "",
    status: [BearbeitungsStatus.InKommissionierung, BearbeitungsStatus.Offen, BearbeitungsStatus.Teillieferung],
    typ: [],
    lager: [],
    assignedTo: [""],
    orderDate: null,
    dueDate: new Date(),
    orderDateCondition: "eq",
    dueDateCondition: "lt",
};
