import { Alert, Button, LinearProgress, Snackbar, TextField } from "@mui/material";
import { DataGridPro, GridActionsCellItem, GridColDef, GridRowParams } from "@mui/x-data-grid-pro";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import { useWarenkorb } from "./useWarenkorb";
import { CartItem } from "./CartItem";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Artikel } from "../../../Models/Artikel";
import useAuthentication from "./../../../authentication/useAuthentication";
import useGetDynamicPath from "../../hooks/useGetDynamicPath";
import { ArtikelWithPuffer } from "./../../../Models/ArtikelWithPuffer";

const ARTIKEL_URL = "/angelo/firma/{firma}/lager/{lager}/artikelzuweisung";
export default function Warenkorb() {
    const { cartItemCount, cartLager } = useContext(AppContext);

    if (cartItemCount === 0 || cartLager === undefined) {
        return (
            <div className="contentDiv">
                <h3>Lager: {cartLager?.name}</h3>
                <Alert severity="info">Der Warenkorb ist leer.</Alert>
            </div>
        );
    }

    return <WarenkorbInhalt />;
}

const WarenkorbInhalt = () => {
    const { firmaIdAnforderer } = useAuthentication();
    const { cartLager, setCartItemCount, amountOffeneAnforderungen, setAmountOffeneAnforderungen } = useContext(AppContext);

    const { entries, loaded, updatingBemerkung, updateItem, deleteItem, anfordererBemerkung, setBemerkung, fullfill, lager, fullFillError } = useWarenkorb();

    // Need list of artikels to determine allowed order amount
    const { data: artikel, error, load } = useGetDynamicPath<ArtikelWithPuffer>(ARTIKEL_URL);

    const [showSnack, setShowSnack] = useState(false);
    const [isValidAmounts, setIsValidAmounts] = useState(true);

    useEffect(() => {
        if (cartLager) {
            load([
                { key: "firma", value: firmaIdAnforderer },
                { key: "lager", value: cartLager.id.toString() },
            ]);
        }
    }, [lager]);
    const bestellbareMengen = useMemo(() => {
        let mengePerArtikel: { [id: string]: number } = {};

        if (lager && artikel) {
            artikel.forEach((a) => {
                let matchingAuftrag = a.montageAuftraege.find((a) => {
                    //only montageauftraege of the currently selected lager is relevant
                    if (a.lager.id === lager.id) {
                        return true;
                    }
                    return false;
                });

                let auftragsMenge = 0;
                if (matchingAuftrag !== undefined) {
                    auftragsMenge = matchingAuftrag.anzahl;
                }

                let matchingLagerbestand = a.lagerBestaende.find((a) => {
                    //only bestand of the currently selected lager is relevant
                    if (a.lager.id === lager.id) {
                        return true;
                    }
                    return false;
                });

                let lagerMenge = 0;
                if (matchingLagerbestand !== undefined) {
                    lagerMenge = matchingLagerbestand.anzahl;
                }

                let allowedAmount = auftragsMenge - lagerMenge + a.puffer;
                let bestellbar = Math.max(0, allowedAmount);
                mengePerArtikel[a.id] = bestellbar;
            });
        }

        return mengePerArtikel;
    }, [artikel, lager]);

    const getMaxBestellMengeForArtikel = useCallback(
        (a?: Artikel) => {
            if (!a) {
                return 0;
            }
            if (bestellbareMengen[a.id]) {
                return bestellbareMengen[a.id];
            }

            return undefined;
        },
        [bestellbareMengen]
    );

    useEffect(() => {
        if (loaded) {
            setCartItemCount(entries.length);
        }
    }, [loaded, entries, setCartItemCount]);

    useEffect(() => {
        for (const entry in entries) {
            const maxAmount = getMaxBestellMengeForArtikel(entries[entry].artikel);
            if (maxAmount && maxAmount < entries[entry].anzahl) {
                setIsValidAmounts(false);
                return;
            }
        }

        setIsValidAmounts(true);
    }, [entries, bestellbareMengen, getMaxBestellMengeForArtikel]);

    const [bemerkungVal, setBemerkungVal] = useState("");

    useEffect(() => {
        if (anfordererBemerkung) {
            setBemerkungVal(anfordererBemerkung);
        } else {
            setBemerkungVal("");
        }
    }, [anfordererBemerkung]);

    const columns: GridColDef<CartItem>[] = [
        {
            field: "anzahl",
            headerAlign: "left",
            headerName: "Anzahl",
            type: "number",
            editable: true,
            valueParser: (value, row, column, apiRef) => {
                if (value < 1) {
                    return 1;
                }
                let maxAmount = getMaxBestellMengeForArtikel(row!.artikel);
                if (maxAmount && value <= maxAmount) {
                    return value;
                }
                return maxAmount ?? 0;
            },
            renderCell: (p) => {
                return (
                    <>
                        <span>{p.value}</span>
                        <EditOutlinedIcon color="action" style={{ marginLeft: "10px" }} />
                    </>
                );
            },
            flex: 1,
        },
        {
            field: "Maximale Bestellmenge",
            flex: 2,
            type: "custom",
            valueGetter: (p, row: CartItem) => {
                return getMaxBestellMengeForArtikel(row.artikel);
            },
        },
        {
            field: "funktionsklasse",
            headerName: "Funktionsklasse",
            flex: 2,
            valueGetter: (p, row: CartItem) => {
                return row.artikel.funktionsklasse;
            },
        },

        {
            field: "anzeigename",
            headerName: "Name",
            flex: 4,
            valueGetter: (p, row: CartItem) => {
                return row.artikel.name;
            },
        },
        {
            field: "artikelnummer",
            headerName: "Artikelnummer",
            flex: 3,
            valueGetter: (p, row: CartItem) => {
                return row.artikel.artikelnummer;
            },
        },
        {
            field: "actions",
            type: "actions",
            flex: 1,
            getActions: (params: GridRowParams) => [
                <GridActionsCellItem key="remove" showInMenu={false} icon={<RemoveShoppingCartIcon />} label="Remove" onClick={() => deleteItem(params.id)} />,
            ],
        },
    ];

    return (
        <div className="contentDiv">
            {(error !== "" || fullFillError !== "") && (
                <Alert severity="error">
                    <span>{"Ein Fehler ist aufgetreten! " + error + fullFillError}</span>
                </Alert>
            )}
            <h3>Lager: {lager?.name}</h3>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={() => {
                    setShowSnack(false);
                }}
                open={showSnack}
                autoHideDuration={5000}
            >
                <Alert
                    onClose={() => {
                        setShowSnack(false);
                    }}
                    severity="success"
                    sx={{ width: "100%" }}
                >
                    Anforderung angenommen!
                </Alert>
            </Snackbar>
            {entries.length === 0 ? (
                <Alert severity="info">Der Warenkorb ist leer.</Alert>
            ) : (
                <>
                    <DataGridPro
                        autoHeight
                        density="compact"
                        loading={!loaded}
                        getRowId={(r) => r.id}
                        rows={entries}
                        columns={columns}
                        hideFooter={true}
                        slots={{ loadingOverlay: LinearProgress as any }}
                        processRowUpdate={async (updatedRow, originalRow) => {
                            await updateItem(updatedRow);
                            return updatedRow;
                        }}
                        onProcessRowUpdateError={() => {}}
                    />
                    <br></br>
                    <TextField
                        multiline={true}
                        rows={5}
                        maxRows={10}
                        sx={{ width: "100%" }}
                        onChange={(el) => {
                            setBemerkungVal(el.target.value);
                        }}
                        value={bemerkungVal}
                        label="Bemerkung zu diesem Abruf"
                        placeholder="Bemerkung"
                    />
                    <br />
                    {!isValidAmounts && <Alert severity="warning">Die bestellte Anzahl ist größer als die maximale Bestellmenge!</Alert>}
                    <br />
                    <Button
                        variant="contained"
                        disabled={!loaded || updatingBemerkung}
                        sx={{ marginRight: 10 }}
                        onClick={async () => {
                            await setBemerkung(bemerkungVal);
                        }}
                    >
                        Bemerkung speichern
                    </Button>
                    <Button
                        disabled={entries.length === 0 || !isValidAmounts || !loaded || updatingBemerkung}
                        variant="contained"
                        onClick={async () => {
                            await setBemerkung(bemerkungVal);
                            let fullfilled = await fullfill();
                            if (fullfilled) {
                                setShowSnack(true);
                                setAmountOffeneAnforderungen(amountOffeneAnforderungen + 1);
                                setCartItemCount(0);
                            }
                        }}
                    >
                        Bestellen
                    </Button>
                </>
            )}
        </div>
    );
};
