import { Box } from "@mui/material";
import useGetFiltered from "applications/hooks/useGetFiltered";
import { Anforderung } from "Models/Anforderung";
import { useEffect, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { DefaultAnforderungsFilter } from "../AnforderungsFilter/AnfoderungsFilter";
import { AnforderungsSelection } from "./AnforderungsSelection";
import { DisplayAnforderung } from "./DisplayAnforderung";
import { ErrorDialog } from "applications/components/ErrorDialog";

const route = "/angelo/logistik/anforderungen";

function AnforderungInBearbeitungList() {
    const { data, error, isLoading, setFilter, setSort, load, setPagination } = useGetFiltered<Anforderung[]>(route, DefaultAnforderungsFilter, { page: 0, pageSize: 20 });
    const { bestellnummer } = useParams();

    const [selectedAnforderung, setSelectedAnforderung] = useState<string>();

    const navigate = useNavigate();

    // callback when Anforderung is changed - reselection of Anforderung by 'bestellnummer'.
    const selectedAnforderungChanged = (id: string) => {
        let anf = data.results.find((a) => a.id === id);
        if (anf) {
            let path = generatePath("/logistik/anforderungsbearbeitung/:bestellnummer", {
                bestellnummer: anf.bestellnummer,
            });
            navigate(path);
        }
    };

    // Page is loaded, either with mennuItemClick, or with passed 'bestellnummer' in path.
    useEffect(() => {
        if (data?.results?.length > 0) {
            // data is loaded
            if (bestellnummer) {
                // bestellnummer from path - setSelectedANforderung accordingly
                let newSelAnforderungid = data.results.find((a) => a.bestellnummer?.toString() === bestellnummer)?.id;
                if (newSelAnforderungid && newSelAnforderungid !== selectedAnforderung) {
                    setSelectedAnforderung(newSelAnforderungid);
                }
            } else {
                // no bestellnummer - select first Anforderung.
                setSelectedAnforderung(data.results[0].id);
            }
        }
    }, [data, bestellnummer]);

    return (
        <div className="contentDiv">
            {error && <ErrorDialog title="Ein Fehler ist aufgetreten!" error={error} />}
            <Box
                sx={{
                    flexGrow: 1,
                    display: { md: "flex" },
                    flex: 1,
                    minHeight: "100%",
                }}
            >
                <AnforderungsSelection
                    anforderungen={data.results}
                    anforderungenInTotal={data.count}
                    preSelected={selectedAnforderung}
                    isHistory={false}
                    selectAnforderungCallBack={selectedAnforderungChanged}
                    loading={isLoading}
                    filterChangeCallback={(filter) => {
                        filter.filter.history = false;
                        setFilter(filter.filter);
                    }}
                    sortChangedCallback={(sortitem) => {
                        setSort(sortitem);
                    }}
                    paginationChangedCallback={(page) => {
                        setPagination(page);
                    }}
                />

                {selectedAnforderung && data.results?.length > 0 && (
                    <div
                        style={{
                            width: "100%",
                            border: "1px solid rgba(224, 224, 224, 1)",
                            borderRadius: "4px",
                            marginLeft: "5px",
                            padding: "0 15px 15px 15px",
                        }}
                    >
                        <DisplayAnforderung
                            id={selectedAnforderung}
                            anforderungChangedCallback={() => {
                                load();
                            }}
                            editable={true}
                        />
                    </div>
                )}
                {!data.results?.length && (
                    <div
                        style={{
                            width: "100%",
                            border: "1px solid rgba(224, 224, 224, 1)",
                            borderRadius: "4px",
                            marginLeft: "5px",
                            padding: "0 15px 15px 15px",
                            alignContent: "center",
                            textAlign: "center",
                        }}
                    >
                        Es wurden keine Aufträge gefunden.
                    </div>
                )}
            </Box>
        </div>
    );
}

export default AnforderungInBearbeitungList;
