import ScheduleIcon from "@mui/icons-material/Schedule";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ListIcon from "@mui/icons-material/List";
import WidgetsIcon from "@mui/icons-material/Widgets";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import { KommissionierungsStatus } from "../../../Models/Kommissionierung";

type KommissionierungsStatusTextProps = {
    status: KommissionierungsStatus;
    withIcon?: boolean;
};

export const KommissionierungsStatusAsText = (props: Readonly<KommissionierungsStatusTextProps>) => {
    const { status, withIcon } = props;

    const getIcon = (status: KommissionierungsStatus) => {
        switch (status) {
            case KommissionierungsStatus.Abgeschlossen:
                return <CheckCircleOutlineIcon color="action" />;
            case KommissionierungsStatus.Versendet:
                return <LocalShippingIcon color="action" />;
            case KommissionierungsStatus.InKommissionierung:
                return <ListIcon color="action" />;
            case KommissionierungsStatus.Offen:
                return <ScheduleIcon color="action" />;
            case KommissionierungsStatus.Versandbereit:
                return <WidgetsIcon color="action" />;
            case KommissionierungsStatus.WarenAngenommen:
                return <AssignmentReturnIcon color="action" />;
            case KommissionierungsStatus.WarenIO:
                return <ThumbUpIcon color="action" />;
            default:
                return <ScheduleIcon color="action" />;
        }
    };
    const getText = (status: KommissionierungsStatus) => {
        switch (status) {
            case KommissionierungsStatus.Abgeschlossen:
                return "Abgeschlossen";
            case KommissionierungsStatus.Versendet:
                return "Versendet";
            case KommissionierungsStatus.InKommissionierung:
                return "In Kommissionierung";
            case KommissionierungsStatus.Offen:
                return "Offen";
            case KommissionierungsStatus.Versandbereit:
                return "Versandbereit";
            case KommissionierungsStatus.WarenAngenommen:
                return "Ware angenommen";
            case KommissionierungsStatus.WarenIO:
                return "Ware IO";
            default:
                return "Offen";
        }
    };

    return (
        <>
            {withIcon && getIcon(status)}{" "}
            <span
                style={{
                    marginLeft: withIcon ? "10px" : "0px",
                    verticalAlign: "top",
                }}
            >
                {getText(status)}{" "}
            </span>
        </>
    );
};
