import dayjs from "dayjs";
import { dateFormat } from "./../../../../applications/components/DateDisplay";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import { BearbeitungsStatus } from "./../../../../Models/Anforderung";

type FaelligkeitDisplayProps = {
    date: Date;
    status: BearbeitungsStatus;
    showTime?: boolean;
};
export default function FaelligkeitDisplay(props: Readonly<FaelligkeitDisplayProps>) {
    const { date, status, showTime } = props;
    const theme = useTheme();

    if (status === BearbeitungsStatus.Abgeschlossen) {
        return <Box>{dateFormat(date, showTime)}</Box>;
    }

    let now = dayjs(new Date());
    let dueDate = dayjs(date);
    let diff = dueDate.diff(now, "day");

    let color = theme.palette.text.primary;
    if (diff < 2) {
        color = theme.palette.warning.main;
    }
    if (diff < 0) {
        color = theme.palette.error.main;
    }

    return <Box color={color}>{dateFormat(date, showTime)}</Box>;
}
