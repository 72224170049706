import { Box, LinearProgress, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import DateDisplay from "applications/components/DateDisplay";
import DurationDisplay from "applications/components/DurationDisplay";
import ErrorDisplay from "applications/components/ErrorDisplay";
import ImportStatusDisplay, { getImportStatusAsText } from "applications/components/ImportStatusDisplay";
import { getInventoryImportModelAsText } from "applications/components/InventoryImportModelDisplay";
import useGetDynamicPath from "applications/hooks/useGetDynamicPath";
import CustomGridToolbar from "layout/CustomGridToolbar";
import InventoryImport from "Models/InventoryImport";
import InventoryImportItem from "Models/InventoryImportItem";
import { useEffect, useState } from "react";

type ImportDetailsProps = {
    importModel: InventoryImport;
};
export default function ImportDetails(props: Readonly<ImportDetailsProps>) {
    const { importModel } = props;
    const { data, error, isLoading, load } = useGetDynamicPath<InventoryImportItem>("/angelo/admin/inventoryimport/{id}/items");
    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 25 });

    const columns: GridColDef[] = [
        {
            field: "status",
            headerName: "Status",
            width: 150,
            valueGetter: (param) => getImportStatusAsText(param),
            renderCell: (param) => <ImportStatusDisplay status={param.row.status} />,
        },
        { field: "item", headerName: "Item", width: 150 },
        { field: "anzahl", headerName: "Anzahl", width: 150 },
        { field: "lager", headerName: "Lager", width: 150 },
        { field: "bemerkung", headerName: "Bemerkung", width: 400 },
    ];

    useEffect(() => {
        if (importModel.id) {
            let params = [{ key: "id", value: "" + importModel.id }];
            load(params);
        }
    }, [importModel]);

    return (
        <>
            <h2>Details</h2>
            <Table size="small" sx={{ border: "1px solid #E0E0E0" }}>
                <TableBody>
                    <TableRow>
                        <TableCell width={"18%"} variant="head">
                            Typ
                        </TableCell>
                        <TableCell width={"32%"}>{getInventoryImportModelAsText(importModel.typ)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width={"18%"} variant="head">
                            Status
                        </TableCell>
                        <TableCell width={"32%"}>
                            <ImportStatusDisplay status={importModel.status} />
                        </TableCell>
                        <TableCell width={"15%"} variant="head">
                            Elemente
                        </TableCell>
                        <TableCell width={"35%"}>
                            {importModel.amount} ({importModel.amountImported} importiert / {importModel.amountFailed} fehlerhaft)
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width={"15%"} variant="head">
                            Start
                        </TableCell>
                        <TableCell width={"35%"}>
                            <DateDisplay date={importModel.createdAt} showTime={true} />
                        </TableCell>
                        <TableCell width={"18%"} variant="head">
                            Dauer
                        </TableCell>
                        <TableCell width={"32%"}>
                            <DurationDisplay start={importModel.createdAt} end={importModel.finishedAt} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width={"15%"} variant="head">
                            Meldung
                        </TableCell>
                        <TableCell colSpan={3} width={"35%"}>
                            {importModel.importError ?? "-"}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <h2>Importierte Elemente</h2>
            <Box>
                {error && <ErrorDisplay title="Fehler beim Laden der Details" error={error} />}
                <DataGridPro
                    pagination
                    pageSizeOptions={[25, 50, 100]}
                    onPaginationModelChange={(newModel) => setPaginationModel(newModel)}
                    paginationModel={paginationModel}
                    autoHeight
                    loading={isLoading}
                    columns={columns}
                    rows={data ?? []}
                    density="compact"
                    slots={{
                        toolbar: CustomGridToolbar,
                        loadingOverlay: LinearProgress as any,
                    }}
                />
            </Box>
        </>
    );
}
