import { Box, Button } from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import DateDisplay from "applications/components/DateDisplay";
import ErrorDisplay from "applications/components/ErrorDisplay";
import ImportStatusDisplay, { getImportStatusAsText } from "applications/components/ImportStatusDisplay";
import { getInventoryImportModelAsText } from "applications/components/InventoryImportModelDisplay";
import useGet from "applications/hooks/useGet";
import InventoryImport from "Models/InventoryImport";
import { useEffect } from "react";

type ImportsListProps = {
    changed: number;
    onSelected: (model: InventoryImport) => void;
};

export default function ImportsList(props: Readonly<ImportsListProps>) {
    const { onSelected, changed } = props;
    const { data, error, isLoading, load } = useGet<InventoryImport>("/angelo/admin/inventoryimport");

    useEffect(() => {
        if (changed > 0 && !isLoading) {
            load();
        }
    }, [changed]);

    const columns: GridColDef[] = [
        { field: "typ", headerName: "Name", width: 150, valueGetter: (param) => getInventoryImportModelAsText(param) },
        { field: "createdAt", headerName: "Datum", width: 120, renderCell: (params) => <DateDisplay showTime={true} date={params.value as Date} /> },
        {
            field: "status",
            headerName: "Status",
            width: 110,
            valueGetter: (param) => getImportStatusAsText(param),
            renderCell: (param) => <ImportStatusDisplay status={param.row.status} />,
        },
        { field: "amount", headerName: "Elemente", width: 100 },
        { field: "amountImported", headerName: "Importiert", width: 100 },
        { field: "amountFailed", headerName: "Fehler", width: 90 },
    ];

    return (
        <>
            <h2>Importe</h2>
            <Box>
                <Button disabled={isLoading} size="small" variant="outlined" onClick={() => load()}>
                    Neu Laden
                </Button>
                {error && <ErrorDisplay error={error} title="Fehler beim laden der Daten." />}
                <DataGridPro density="compact" loading={isLoading} columns={columns} rows={data ?? []} onRowClick={(params) => onSelected(params.row)} />
            </Box>
        </>
    );
}
