import { Anforderung } from "@/Models/Anforderung";
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, LinearProgress, MenuItem, Select } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import Kommissionierer from "@/Models/Kommissionierer";
import useGet from "./../../../../applications/hooks/useGet";
import usePutById from "./../../../../applications/hooks/usePutById";
import ErrorDisplay from "./../../../../applications/components/ErrorDisplay";

type EditKommissioniererForAnforderungProps = {
    anforderung: Anforderung;
    onChange: () => void;
};
export default function EditKommissioniererForAnforderung(props: Readonly<EditKommissioniererForAnforderungProps>) {
    const { anforderung, onChange } = props;
    const [showDialog, setShowDialog] = useState(false);

    return (
        <>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box>
                    <Button onClick={() => setShowDialog(true)} size="small" variant="outlined" endIcon={<EditIcon fontSize="small" />}>
                        Zuweisen
                    </Button>
                </Box>
            </Box>
            {showDialog && (
                <EditBearbeiterDialog
                    anforderungId={anforderung.id}
                    bearbeiter={anforderung.kommissionierer}
                    show={showDialog}
                    onClose={() => setShowDialog(false)}
                    onChange={() => onChange()}
                />
            )}
        </>
    );
}

type PutKommissioniererForAnforderungModel = {
    anforderungId: number;
    kommissionierer: Kommissionierer[];
};

type EditBearbeiterDialogProps = {
    anforderungId: string;
    bearbeiter: Kommissionierer[];
    show: boolean;
    onChange: () => void;
    onClose: () => void;
};
function EditBearbeiterDialog(props: Readonly<EditBearbeiterDialogProps>) {
    const { show, anforderungId, bearbeiter, onChange, onClose } = props;
    const [open, setOpen] = useState(show);

    const { data, error, isLoading } = useGet<Kommissionierer>("logistik/kommissionierer");
    const { data: putData, error: putError, isLoading: putIsLoading, putObject } = usePutById<PutKommissioniererForAnforderungModel>("logistik/anforderungen/{id}/kommissionierer");

    const [selectedBearbeiter, setSelectedBearbeiter] = useState<string[]>(bearbeiter.map((b) => b.userId.toString()));

    useEffect(() => {
        setOpen(show);
    }, [show]);

    const close = () => {
        setOpen(false);
        onClose();
    };

    const save = () => {
        if (data) {
            let komms = data
                .filter((k) => selectedBearbeiter.includes(k.userId))
                .map((k) => {
                    return { userId: k.userId, name: k.name } as Kommissionierer;
                });
            let obj = {
                id: parseInt(anforderungId),
                anforderungId: parseInt(anforderungId),
                kommissionierer: komms,
            } as PutKommissioniererForAnforderungModel;

            putObject(obj);
        }
    };

    useEffect(() => {
        if (putData) {
            onChange();
            close();
        }
    }, [putData]);

    const handleChange = (event: any) => {
        let newValue = event.target.value;
        setSelectedBearbeiter(newValue);
    };

    return (
        <Dialog open={open} onClose={() => close()} maxWidth="lg" fullWidth>
            <DialogTitle>Kommissionierer auswählen</DialogTitle>
            <DialogContent>
                {error && <ErrorDisplay title="Fehler beim laden der Kommissionierer" error={error} />}
                <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
                    <Box sx={{ flex: 1 }}>Bearbeiter</Box>
                    <Box sx={{ flex: 10 }}>
                        {(isLoading || putIsLoading) && <LinearProgress />}
                        {error && <ErrorDisplay title="Fehler beim laden der Kommissionierer" error={error} />}
                        {putError && <ErrorDisplay title="Fehler beim speichern der Kommissionierer" error={putError} />}
                        {data && (
                            <FormControl sx={{ m: 1, width: "100%" }}>
                                <Select
                                    name="Bearbeiter"
                                    multiple
                                    fullWidth
                                    size="small"
                                    onChange={handleChange}
                                    value={selectedBearbeiter}
                                    renderValue={(selected) => {
                                        let display = data.filter((k) => selected.includes(k.userId)).map((d) => <Chip key={d.userId} label={d.name} />);
                                        return <Box sx={{ display: "flex", flexWrap: "wrap", width: "100%" }}>{display}</Box>;
                                    }}
                                >
                                    {data?.map((k) => (
                                        <MenuItem key={k.userId} value={k.userId}>
                                            {k.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => save()} disabled={isLoading || putIsLoading} variant="outlined" size="small" startIcon={<SaveIcon fontSize="small" />}>
                    Speichern
                </Button>
                <Button disabled={isLoading} onClick={() => close()} variant="outlined" size="small" startIcon={<CloseIcon fontSize="small" />}>
                    Schließen
                </Button>
            </DialogActions>
        </Dialog>
    );
}
