import { InventoryImportModelType } from "Models/InventoryImport";

export function getInventoryImportModelAsText(type: InventoryImportModelType) {
    switch (type) {
        case InventoryImportModelType.LAGER_BECK: // TODO: Can nach Umstellung entfernt werden.
            return "Lager Beck";
        case InventoryImportModelType.LAGER_RUECKLOGISTIK:
            return "Lager Rücklogistik";
        case InventoryImportModelType.LAGER_QS:
            return "Lager QS";
        case InventoryImportModelType.LAGER_ZENTRAL:
            return "Zentrallager";
        case InventoryImportModelType.LAGER_DEZENTRAL:
            return "Dienstleisterlager";
        case InventoryImportModelType.MONTAGEAUFTRAEGE:
            return "Montageaufträge";
        case InventoryImportModelType.LAGERNAMEN:
            return "Lagernamen";
        default:
            return type;
    }
}
