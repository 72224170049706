import { Box, Button, LinearProgress } from "@mui/material";
import usePost from "applications/hooks/usePost";
import { InventoryImportModelType } from "Models/InventoryImport";
import InventoryImportRequestModel from "./InventoryImportRequestModel";
import ErrorDisplay from "applications/components/ErrorDisplay";
import { useEffect } from "react";

type StartInventoryImportProps = {
    text: string;
    type: InventoryImportModelType;
    onChange: () => void;
};

export default function StartInventoryImport(props: Readonly<StartInventoryImportProps>) {
    const { text, type, onChange } = props;
    const { data, error, isLoading, createObject } = usePost<InventoryImportRequestModel>("/angelo/admin/inventoryimport");

    const item: InventoryImportRequestModel = {
        id: "0",
        typ: type,
    };

    useEffect(() => {
        if (data) {
            alert("Import gestartet");
            onChange();
        }
    }, [data]);

    return (
        <Box sx={{ marginRight: 2 }}>
            {isLoading && <LinearProgress />}
            <Button disabled={isLoading} variant="outlined" size="small" onClick={() => createObject(item)}>
                Lade {text}
            </Button>
            {error && <ErrorDisplay title="Fehler beim Starten" error={error} />}
        </Box>
    );
}
