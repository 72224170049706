import { useEffect, useState } from "react";
import { Lager, LAGERNUMMER_QS, LAGERNUMMER_RUECKLOGISTIK, LAGERNUMMER_ZENTRALLAGER } from "Models/Lager";
import axios from "ApiClient";

const VIEW_LAGER_URL = "/angelo/logistik/lager";

const useGetLager = (filterSpecialLager: boolean) => {
    const [data, setData] = useState<Lager[]>([]);
    const [error, setError] = useState("");
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const loadLager = async () => {
            try {
                const response = await axios.get<Lager[]>(VIEW_LAGER_URL);
                let json = response.data;
                if (filterSpecialLager) {
                    json = json.filter((l) => {
                        return ![LAGERNUMMER_RUECKLOGISTIK, LAGERNUMMER_QS, LAGERNUMMER_ZENTRALLAGER].includes(l.nummer);
                    });
                }
                setData(json);
            } catch (error: any) {
                console.log("Fehler beim Laden des Lagers", error);
                setError(error.message);
            } finally {
                setLoaded(true);
            }
        };
        loadLager();
    }, []);
    return { data, error, loaded };
};

export default useGetLager;
