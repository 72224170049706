import { Box } from "@mui/material";
import InventoryImport, { InventoryImportModelType } from "Models/InventoryImport";
import ImportsList from "./ImportsList";
import { useState } from "react";
import ImportDetails from "./ImportDetails";
import StartInventoryImport from "./StartInventoryImport";

export default function InventoryImports() {
    const [selected, setSelected] = useState<InventoryImport | null>(null);
    const [updateCounter, setUpdateCounter] = useState(0);

    return (
        <div className="contentDiv">
            <h1>Status der nächtlichen SAP Importe der Montageaufträge und Lagerbestände</h1>

            <Box sx={{ display: "flex" }}>
                <StartInventoryImport
                    onChange={() => {
                        setUpdateCounter(updateCounter + 1);
                    }}
                    text="Lagernamen"
                    type={InventoryImportModelType.LAGERNAMEN}
                />

                <StartInventoryImport
                    onChange={() => {
                        setUpdateCounter(updateCounter + 1);
                    }}
                    text="Montageaufträge"
                    type={InventoryImportModelType.MONTAGEAUFTRAEGE}
                />

                <StartInventoryImport
                    onChange={() => {
                        setUpdateCounter(updateCounter + 1);
                    }}
                    text="Lagerbestand Zentrallager"
                    type={InventoryImportModelType.LAGER_ZENTRAL}
                />

                <StartInventoryImport
                    onChange={() => {
                        setUpdateCounter(updateCounter + 1);
                    }}
                    text="Lagerbestand Dezentral"
                    type={InventoryImportModelType.LAGER_DEZENTRAL}
                />

                <StartInventoryImport
                    onChange={() => {
                        setUpdateCounter(updateCounter + 1);
                    }}
                    text="Lagerbestand QS"
                    type={InventoryImportModelType.LAGER_QS}
                />

                <StartInventoryImport
                    onChange={() => {
                        setUpdateCounter(updateCounter + 1);
                    }}
                    text="Lagerbestand Rücklogistik"
                    type={InventoryImportModelType.LAGER_RUECKLOGISTIK}
                />
            </Box>

            <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "680px", paddingRight: "10px" }}>
                    <ImportsList changed={updateCounter} onSelected={(id) => setSelected(id)} />
                </Box>
                <Box sx={{ width: "100%" }}>{selected && <ImportDetails importModel={selected} />}</Box>
            </Box>
        </div>
    );
}
