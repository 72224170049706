type DurationDisplayProps = {
    start: Date;
    end: Date;
};
export default function DurationDisplay(props: Readonly<DurationDisplayProps>) {
    const { start, end } = props;

    // incoming times are UTC
    let theStart = start + "Z";
    let theEnd;
    if (!end) {
        let now = new Date();
        theEnd = new Date(
            Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds())
        );
    } else {
        theEnd = end + "Z";
    }

    let endMillis = new Date(theEnd).getTime();
    let startMillis = new Date(theStart).getTime();

    let delta = endMillis - startMillis;

    let seconds = delta / 1000;

    let ss = Math.floor(seconds % 60);
    let mm = Math.floor((seconds % 3600) / 60);
    let HH = Math.floor(seconds / 3600);

    const with2digits = (num: number) => {
        return num.toString().length === 1 ? "0" + num : num;
    };

    if (delta <= 0) {
        return <>-</>;
    }

    if (delta > 0) {
        return (
            <>
                {HH}:{with2digits(mm)}:{with2digits(ss)} (h:min:s)
            </>
        );
    }
}
