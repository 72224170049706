import { Navigate, Route, Routes } from "react-router-dom";
import AllLagerBestand from "./LagerBestand/AllLagerBestand";
import AnforderungInBearbeitungList from "./anforderungen/inbearbeitung/AnforderungInBearbeitungList";
import Historie from "./anforderungen/historie/Historie";
import LieferscheinDrucken from "./Kommissionierung/LieferscheinDrucken";

export default function RoutesLogistik() {
    return (
        <Routes>
            <Route path={"/anforderungsbearbeitung/"} element={<AnforderungInBearbeitungList />} />
            <Route path={"/anforderungsbearbeitung/:bestellnummer"} element={<AnforderungInBearbeitungList />} />

            <Route path={"/historie/:bestellnummer"} element={<Historie />} />
            <Route path={"/historie/"} element={<Historie />} />

            <Route path="/lager" element={<AllLagerBestand />} />

            <Route path="/lieferschein/:id" element={<LieferscheinDrucken />} />

            <Route path="/*" element={<Navigate to={"anforderungsbearbeitung/"} />} />
        </Routes>
    );
}
