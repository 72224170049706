import { LinearProgress } from "@mui/material";
import { DataGridPro, GridColDef, GridPaginationModel, GridSortModel } from "@mui/x-data-grid-pro";
import { dateFormat } from "applications/components/DateDisplay";
import { defaultPagination, SortItem } from "applications/hooks/useGetFiltered";
import { BearbeitungsStatusAsText } from "applications/logistik/anforderungen/BearbeitungsStatusAsText";
import AnforderungsTypIcon from "layout/AnforderungsTypIcon";
import CustomGridToolbarSearchOnServer from "layout/CustomGridToolbarSearchOnServer";
import { Anforderung } from "Models/Anforderung";
import { Firma } from "Models/Firma";
import { Lager } from "Models/Lager";
import { useEffect, useState } from "react";

export type AnforderungsSelectionBestellerProps = {
    anforderungen: Anforderung[];
    anforderungenInTotal: number;
    loading: boolean;
    preSelected?: string;
    selectAnforderungCallBack: (id: string) => void;
    filterChangeCallback: (searchPhrase: string) => void;
    sortChangedCallback: (sortModel: SortItem) => void;
    paginationChangedCallback: (page: GridPaginationModel) => void;
};
export const AnforderungsSelectionBesteller = (props: Readonly<AnforderungsSelectionBestellerProps>) => {
    const { anforderungen, anforderungenInTotal, loading, preSelected, selectAnforderungCallBack, filterChangeCallback, sortChangedCallback, paginationChangedCallback } = props;
    const [selectedAnforderungId, setSelectedAnforderungId] = useState<string>();

    const [paginationModel, setPaginationModel] = useState(defaultPagination);

    const [sort, setSort] = useState<SortItem>();

    // preselected value changes or is set
    useEffect(() => {
        if (preSelected && preSelected !== selectedAnforderungId) {
            setSelectedAnforderungId(preSelected);
        }
    }, [preSelected, selectedAnforderungId]);

    useEffect(() => {
        sortChangedCallback(sort as SortItem);
    }, [sort, sortChangedCallback]);

    const selectAnforderung = (id: string) => {
        setSelectedAnforderungId(id);
        selectAnforderungCallBack(id);
    };

    const columns: GridColDef<Anforderung>[] = [
        {
            field: "bearbeitungsStatus",
            headerName: "Status",
            width: 60,
            renderCell: (p) => {
                return <BearbeitungsStatusAsText status={p.value} />;
            },
        },
        {
            field: "anforderungsTyp",
            headerName: "Typ",
            width: 50,
            renderCell: (elem) => <AnforderungsTypIcon type={elem.value} />,
        },
        { field: "bestellnummer", headerName: "Nummer", width: 80 },
        {
            field: "createdAt",
            headerName: "Datum",
            width: 85,
            valueFormatter: (a) => {
                return dateFormat(a);
            },
        },
        {
            field: "anforderer",
            headerName: "Anforderer",
            width: 150,
            valueGetter: (a: Firma) => {
                return a.name;
            },
        },
        {
            field: "lager",
            headerName: "Lager",
            width: 60,
            valueGetter: (l: Lager) => {
                return l?.nummer;
            },
            valueFormatter: (l: Lager) => {
                return l?.nummer;
            },
        },
    ];

    return (
        <DataGridPro
            disableColumnMenu={true}
            disableMultipleColumnsSorting={true}
            paginationMode="server"
            rowCount={anforderungenInTotal}
            pagination
            pageSizeOptions={[20]}
            paginationModel={paginationModel}
            onPaginationModelChange={(pagination) => {
                setPaginationModel(pagination);
                paginationChangedCallback(pagination);
            }}
            sortingMode="server"
            onSortModelChange={(sortModel: GridSortModel) => {
                if (sortModel[0]) {
                    setSort(sortModel[0]);
                }
            }}
            loading={loading}
            getRowId={(r) => r.id}
            onRowClick={(p) => {
                selectAnforderung(p.row.id);
            }}
            rows={anforderungen}
            columns={columns}
            disableRowSelectionOnClick
            sx={{
                minWidth: "500px",
                maxWidth: "500px",

                "& .MuiDataGrid-row:hover": {
                    opacity: 0.8,
                    cursor: "pointer",
                },

                "& .selected": {
                    backgroundColor: "#ddd !important",
                },
            }}
            getRowClassName={(params) => {
                if (params.row.id === selectedAnforderungId) {
                    return "selected";
                }
                return "";
            }}
            getRowHeight={() => "auto"}
            autoHeight
            density="compact"
            slots={{
                toolbar: CustomGridToolbarSearchOnServer as any,
                loadingOverlay: LinearProgress as any,
            }}
            slotProps={{
                toolbar: {
                    onSearch: (searchPhrase: string) => {
                        filterChangeCallback(searchPhrase);
                    },
                },
            }}
        />
    );
};
