import { Navigate, Route, Routes } from "react-router-dom";
import ArtikelMgmt from "./artikelManagement/ArtikelMgmt";
import UserManagement from "./userManagement/UserManagement";
import FirmenMgmt from "./firmaManagement/FirmenMgmt";
import InventoryImports from "./inventoryImports/InventoryImports";

export default function RoutesAdministration() {
    return (
        <Routes>
            <Route path="/artikel" element={<ArtikelMgmt />}></Route>
            <Route path="/users" element={<UserManagement />} />
            <Route path="/firmen" element={<FirmenMgmt />} />
            <Route path="/inventoryImports" element={<InventoryImports />} />
            <Route path="/*" element={<Navigate to={"artikel"} />} />
        </Routes>
    );
}
