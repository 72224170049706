import { useEffect, useMemo, useState } from "react";
import { DataGridPro, GridColDef, GridRowParams } from "@mui/x-data-grid-pro";
import { Alert, AlertTitle, Box, Button, Grid, LinearProgress, Switch } from "@mui/material";
import { ArtikelZuweisungRequest } from "./ArtikelZuweisungRequest";
import usePutArtikelZuweisungen from "./usePutArtikelZuweisungen";
import { ArtikelWithPuffer } from "Models/ArtikelWithPuffer";
import { Lager } from "Models/Lager";
import CustomGridToolbar from "layout/CustomGridToolbar";
import useGetDynamicPath from "applications/hooks/useGetDynamicPath";

type ArtikelListeProps = {
    lager: Lager;
    onChange: () => void;
};
export default function ArtikelListe(props: Readonly<ArtikelListeProps>) {
    const { lager, onChange } = props;
    const [lagerId, setLagerId] = useState<string>(lager.id.toString());
    const { data, isLoading, error, load } = useGetDynamicPath<ArtikelWithPuffer>("/angelo/admin/lager/{lagerId}/artikelzuweisung");
    const { result, saving, putError, update } = usePutArtikelZuweisungen(lager.id);

    // contains array of articles to be changed for this lager
    const [changedArticles, setChangedArticles] = useState<ArtikelZuweisungRequest[]>([]);

    // In order to allow to add ALL articles at once, we need to decouple data from displayArticles
    const [displayArticles, setDisplayArticles] = useState<ArtikelWithPuffer[]>([]);

    useEffect(() => {
        if (data) {
            const sortedArtikel = data.toSorted((a, b) => {
                return a.artikelnummer.localeCompare(b.artikelnummer);
            });
            setDisplayArticles([...sortedArtikel]);
        }
    }, [data]);

    useEffect(() => {
        setChangedArticles([]);
        const l = lager?.id?.toString();
        if (l !== lagerId) {
            setLagerId(l);
        }
    }, [lager]);

    useEffect(() => {
        load([{ key: "lagerId", value: lagerId }]);
    }, [lagerId]);

    const addChangeArticle = useMemo(
        () => (article: ArtikelWithPuffer) => {
            if (lager) {
                let zuweisung = {
                    artikelId: article.id,
                    artikelnummer: article.artikelnummer,
                    lagerId: lager.id,
                    puffer: article.puffer,
                } as ArtikelZuweisungRequest;
                let newArticles = [...changedArticles];
                let exists = newArticles.find((a) => a.artikelId === zuweisung.artikelId);
                if (exists) {
                    exists.puffer = zuweisung.puffer;
                } else {
                    newArticles.push(zuweisung);
                }
                setChangedArticles(newArticles);
            }
        },
        [lager, changedArticles, setChangedArticles]
    );

    // Adds all articles to this lager with default puffer of 50.
    const addAllArticles = () => {
        let updatedArticles = [...displayArticles];
        updatedArticles.forEach((a) => {
            a.puffer = 50;
            addChangeArticle(a);
        });
        setDisplayArticles(updatedArticles);
    };

    const saveZuweisungen = async () => {
        let completeArticles = [...changedArticles];
        if (!displayArticles) return;
        displayArticles.forEach((a) => {
            if (a.puffer > 0 && !completeArticles.find((ca) => ca.artikelId === a.id)) {
                completeArticles.push({
                    artikelId: a.id,
                    artikelnummer: a.artikelnummer,
                    lagerId: lager.id,
                    puffer: a.puffer,
                } as ArtikelZuweisungRequest);
            }
        });
        await update(completeArticles);
        onChange();
        setChangedArticles([]);
    };

    const columns: GridColDef<ArtikelWithPuffer>[] = [
        {
            field: "actions",
            type: "actions",
            getActions: (p: GridRowParams<ArtikelWithPuffer>) => {
                return [
                    <Switch
                        key="switch"
                        size="small"
                        checked={p.row.puffer > 0}
                        onClick={() => {
                            if (p.row.puffer > 0) {
                                p.row.puffer = 0;
                            } else {
                                p.row.puffer = 50;
                            }
                            addChangeArticle(p.row);
                        }}
                    />,
                ];
            },
        },
        {
            field: "artikelnummer",
            headerName: "Nummer",
            width: 150,
        },
        {
            field: "funktionsklasse",
            headerName: "Funktionsklasse",
            width: 150,
        },
        {
            field: "puffer",
            headerName: "Puffer",
            type: "number",
            width: 80,
            editable: true,
        },
        {
            field: "activated",
            headerName: "Aktiv",
            renderCell: (params) => {
                return params.row.activated ? "Ja" : "Nein";
            },
        },
    ];

    return (
        <>
            <h3>Lager: {lager.name}</h3>
            {result && (
                <Alert severity="success">
                    <AlertTitle>Artikelzuweisung gespeichert</AlertTitle>
                </Alert>
            )}
            {error && (
                <Alert severity="error">
                    <AlertTitle>Fehler beim Laden der Artikel</AlertTitle>
                    {error}
                </Alert>
            )}
            {putError && (
                <Alert severity="error">
                    <AlertTitle>Fehler beim Speichern der Artikelzuweisungen</AlertTitle>
                    {putError}
                </Alert>
            )}
            <Grid container justifyContent="space-between">
                <Button
                    sx={{ marginRight: 2 }}
                    size="small"
                    variant="outlined"
                    disabled={changedArticles.length === 0}
                    onClick={() => {
                        saveZuweisungen();
                    }}
                >
                    Speichern
                </Button>
                <Button color="warning" size="small" variant="outlined" onClick={() => addAllArticles()}>
                    Füge alle Artikel hinzu (Puffer 50)
                </Button>
            </Grid>
            <Box sx={{ height: 500 }}>
                <DataGridPro
                    sx={{ minHeight: 475 }}
                    density="compact"
                    loading={isLoading || saving}
                    getRowId={(r) => r.id}
                    rows={displayArticles}
                    processRowUpdate={async (updatedRow, originalRow) => {
                        addChangeArticle(updatedRow);
                        return updatedRow;
                    }}
                    columns={columns}
                    getRowHeight={() => "auto"}
                    slots={{
                        toolbar: CustomGridToolbar,
                        loadingOverlay: LinearProgress as any,
                    }}
                />
            </Box>
        </>
    );
}
