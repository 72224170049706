import { AnforderungsPosition } from "@/Models/AnforderungsPosition";
import { Artikel } from "@/Models/Artikel";
import { Kommissionierung } from "@/Models/Kommissionierung";
import { Button, LinearProgress, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import ScheduleIcon from "@mui/icons-material/Schedule";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { tableCellClasses } from "@mui/material/TableCell";
import StornoButton from "./StornoButton";
import Storno from "@/Models/Storno";
import { Anforderung } from "@/Models/Anforderung";
import ErrorDisplay from "./../../../../applications/components/ErrorDisplay";
import usePost from "./../../../../applications/hooks/usePost";

export type AnforderungsPositionenTabelleProps = {
    anforderung: Anforderung;
    positionen: AnforderungsPosition[];
    kommissionierungen: Kommissionierung[];
    stornierungen: Storno[];
    stornoCallback: (posId: string) => void;
    onKommissioniert: () => void;
    editable?: boolean;
};
export const AnforderungsPositionenTabelle = (props: AnforderungsPositionenTabelleProps) => {
    const { anforderung, stornoCallback, positionen, kommissionierungen, stornierungen, onKommissioniert, editable } = props;

    const [isuLieferschein, setIsuLieferschein] = useState<string>("");
    const [kommissionierungBemerkung, setKommissionierungBemerkung] = useState<string>("");

    const anforderungid = anforderung.id;

    const { data, error, isLoading, createObject } = usePost<any>(`angelo/logistik/anforderungen/${anforderungid}/kommissionierung`);

    const [kommissionierteMengen, setKommissionierteMengen] = useState<{ [positionsId: string]: number }>({});

    const calculateNochOffen = (pos: AnforderungsPosition, kommissionierungen: Kommissionierung[], stornierungen: Storno[]) => {
        let anzahl = 0;
        kommissionierungen.forEach((k) => {
            k.positionen
                .filter((k) => k.anforderungsPosition.id === pos.id)
                .forEach((p) => {
                    anzahl += p.anzahl;
                });
        });
        stornierungen.forEach((s) => {
            if (s.anforderungsPosition.id === pos.id) {
                anzahl += s.stornierteAnzahl;
            }
        });

        return pos.bestellteAnzahl - anzahl;
    };

    const calculateStorniert = (pos: AnforderungsPosition, stornierungen: Storno[]) => {
        let anzahl = 0;
        stornierungen.forEach((s) => {
            if (s.anforderungsPosition.id === pos.id) {
                anzahl += s.stornierteAnzahl;
            }
        });
        return anzahl;
    };

    const updateZuKommissionieren = (positionId: string, amount: number) => {
        let newKommissionierteMengen = {
            ...kommissionierteMengen,
        };
        newKommissionierteMengen[positionId] = amount;
        setKommissionierteMengen(newKommissionierteMengen);
    };

    const save = async () => {
        let kommPositionen: KommissioniertePosition[] = Object.entries(kommissionierteMengen).map((entry) => {
            return {
                anforderungsPositionId: entry[0],
                kommissionierteAnzahl: entry[1],
            } as KommissioniertePosition;
        });
        setKommissionierteMengen({});

        let obj = {
            id: anforderung.id,
            isuLieferschein: isuLieferschein,
            kommentar: kommissionierungBemerkung,
            kommissioniertePositionen: kommPositionen,
        };
        await createObject(obj);
    };

    useEffect(() => {
        if (data) {
            onKommissioniert();
        }
    }, [data]);

    return (
        <>
            <h3>Abrufpositionen</h3>
            {isLoading && <LinearProgress />}
            {error && <ErrorDisplay title="Fehler beim Kommissionieren" error={error} />}
            <Table
                size="small"
                sx={{
                    [`& .${tableCellClasses.root}`]: {
                        borderBottom: "none",
                        //border: "1px solid black ! important",
                    },
                }}
            >
                <TableHead>
                    <TableRow>
                        <TableCell className="statusCell"></TableCell>
                        <TableCell className="textCell">Funktionsklasse</TableCell>
                        <TableCell className="textCell">Artikelnummer</TableCell>
                        <TableCell className="numbersCell">Bestellt</TableCell>
                        <TableCell className="numbersCell">Offen</TableCell>
                        <TableCell className="numbersCell">Kommissioniert</TableCell>
                        <TableCell className="numbersCell">Storno</TableCell>
                        <TableCell className="lagersCell">Lagerbestände</TableCell>
                        <TableCell className="montageauftraegeCell">Montageaufträge</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {positionen.map((pos: AnforderungsPosition, index: number) => {
                        return (
                            <AnforderungsPositionZeile
                                anforderungsPosition={pos}
                                anforderungOffen={calculateNochOffen(pos, kommissionierungen, stornierungen)}
                                storniert={calculateStorniert(pos, stornierungen)}
                                zuKommissionierenCallBack={(positionId, amount) => updateZuKommissionieren(positionId, amount)}
                                key={index}
                                editable={editable}
                                stornoCallBack={(posId: string) => stornoCallback(posId)}
                            />
                        );
                    })}
                </TableBody>
            </Table>
            {editable && (
                <Table
                    size="small"
                    sx={{
                        [`& .${tableCellClasses.root}`]: {
                            borderBottom: "none",
                            //border: "1px solid black ! important",
                        },
                    }}
                >
                    <TableBody>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell variant="head">Kommentar</TableCell>
                            <TableCell colSpan={2}>
                                <TextField
                                    fullWidth
                                    onChange={(el) => setKommissionierungBemerkung(el.currentTarget.value)}
                                    value={kommissionierungBemerkung}
                                    label="Kommentar"
                                    placeholder="3 Gitterboxen. Gewicht 1000kg"
                                    size="small"
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="spaceHolderKommissionieren"></TableCell>
                            <TableCell className="numbersCell" variant="head">
                                Lieferschein
                            </TableCell>
                            <TableCell className="doubleNumbersCell">
                                <TextField
                                    fullWidth
                                    onChange={(el) => setIsuLieferschein(el.currentTarget.value)}
                                    value={isuLieferschein}
                                    label="ISU Lieferscheinnummer"
                                    placeholder="AB-0815"
                                    size="small"
                                />
                            </TableCell>
                            <TableCell className="lagersCell">
                                {isLoading && <LinearProgress />}
                                <Button
                                    sx={{ margin: "2px" }}
                                    variant="contained"
                                    disabled={
                                        isLoading ||
                                        Object.entries(kommissionierteMengen)
                                            .map((m) => m[1])
                                            .reduce((a, b) => a + b, 0) === 0
                                    }
                                    onClick={async () => {
                                        await save();
                                    }}
                                >
                                    Kommissionieren
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            )}
        </>
    );
};

type KommissioniertePosition = {
    anforderungsPositionId: string;
    kommissionierteAnzahl: number;
};

export type AnforderungsPositionZeileProps = {
    anforderungsPosition: AnforderungsPosition;
    anforderungOffen: number;
    storniert: number;
    zuKommissionierenCallBack: (positionId: string, amount: number) => void;
    stornoCallBack: (posId: string) => void;
    editable?: boolean;
};
export const AnforderungsPositionZeile = (props: AnforderungsPositionZeileProps) => {
    const { stornoCallBack, storniert } = props;
    const [amount, setAmount] = useState(0);

    const pos = props.anforderungsPosition;

    let nochOffen = props.anforderungOffen > 0;

    useEffect(() => {
        setAmount(0);
    }, [props.anforderungsPosition]);

    return (
        <>
            <TableRow style={{ verticalAlign: "top", borderTop: "1px solid black" }}>
                <TableCell>{nochOffen ? <ScheduleIcon color="error" /> : <CheckCircleOutlineIcon color="success" />}</TableCell>
                <TableCell>{pos.artikel.funktionsklasse}</TableCell>
                <TableCell>{pos.artikel.artikelnummer}</TableCell>
                <TableCell>{pos.bestellteAnzahl}</TableCell>
                <TableCell>{props.anforderungOffen}</TableCell>
                <TableCell>
                    {props.editable && nochOffen && (
                        <TextField
                            value={amount}
                            size="small"
                            style={{ maxWidth: "80px" }}
                            type={"number"}
                            onChange={(ev) => {
                                let amount = parseInt(ev.currentTarget.value);
                                if (amount < 0) {
                                    setAmount(0);
                                    return;
                                }
                                if (amount > props.anforderungOffen) {
                                    setAmount(props.anforderungOffen);
                                    return;
                                }
                                setAmount(amount);
                            }}
                            onBlur={(ev) => {
                                props.zuKommissionierenCallBack(pos.id, parseInt(ev.currentTarget.value));
                            }}
                        />
                    )}
                </TableCell>
                <TableCell>{storniert}</TableCell>
                <TableCell rowSpan={2}>
                    <LagerBestaende artikel={pos.artikel} />
                </TableCell>
                <TableCell rowSpan={2}>
                    <MontageBestaende artikel={pos.artikel} />
                </TableCell>
                <TableCell>
                    <StornoButton position={pos} nochOffen={props.anforderungOffen} stornoCallback={() => stornoCallBack(pos.id)} />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell></TableCell>
                <TableCell colSpan={2}>{pos.artikel.name}</TableCell>
            </TableRow>
        </>
    );
};

export const MontageBestaende = (props: { artikel: Artikel }) => {
    const { artikel } = props;

    if (artikel.montageAuftraege && artikel.montageAuftraege.length === 1) {
        return <>{artikel.montageAuftraege[0]?.anzahl}</>;
    } else {
        return <>0</>;
    }
};

export const LagerBestaende = (props: { artikel: Artikel }) => {
    const { artikel } = props;

    if (artikel.lagerBestaende && artikel.lagerBestaende.length === 1) {
        return <>{artikel.lagerBestaende[0]?.anzahl}</>;
    } else {
        return <>0</>;
    }
};
