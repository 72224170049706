import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress, TextField } from "@mui/material";
import ErrorDisplay from "applications/components/ErrorDisplay";
import useGetLager from "applications/logistik/LagerBestand/useGetLager";
import { Lager } from "Models/Lager";
import { useEffect, useState } from "react";
import useLagerOfFirma from "./useLagerOfFirma";
import InfoDisplay from "applications/components/InfoDisplay";

export type AddLagerToFirmaDialogProps = {
    firmaId: number | undefined;
    onClose: () => void;
    assignedLager: Lager[];
};

export default function AddLagerToFirmaDialog(props: Readonly<AddLagerToFirmaDialogProps>) {
    const { firmaId, onClose, assignedLager } = props;
    const { error, loaded, data: result, setFirmenLager: addLagerToFirma } = useLagerOfFirma();

    const { data: allLager } = useGetLager(true);
    const [selected, setSelected] = useState<Lager[]>([]);
    const [saved, setSaved] = useState(false);

    const save = async () => {
        if (firmaId) {
            setSaved(false);
            const newAssignedLager = assignedLager.map((l) => l.nummer);
            selected.forEach((s) => newAssignedLager.push(s.nummer));
            await addLagerToFirma(firmaId, newAssignedLager);
        }
    };

    useEffect(() => {
        if (result.length > 0) {
            setSaved(true);
            setSelected([]);
        }
    }, [result]);

    useEffect(() => {
        setSaved(false);
        setSelected([]);
    }, [firmaId]);

    return (
        <>
            {firmaId && (
                <Dialog
                    maxWidth="md"
                    fullWidth
                    open={firmaId !== undefined}
                    onClose={() => {
                        setSelected([]);
                        onClose();
                    }}
                >
                    <DialogTitle>Lager einer Firma zuweisen</DialogTitle>
                    <DialogContent>
                        {!loaded && <LinearProgress></LinearProgress>}

                        {loaded && (
                            <>
                                {error && <ErrorDisplay title="Fehler beim Speichern:" error={error} />}
                                {saved && <InfoDisplay title="Speichern erfolgreich" message="" />}
                                <DialogContentText>Bitte wähle die Lager aus die du der Firma zuweisen willst: </DialogContentText>
                                {allLager && (
                                    <Autocomplete
                                        multiple
                                        size="small"
                                        options={allLager.sort((a, b) => a.nummer.localeCompare(b.nummer))}
                                        value={selected}
                                        getOptionLabel={(option) => option.nummer + " - " + option.name}
                                        onChange={(e, v) => {
                                            setSelected(v);
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Lager" />}
                                    />
                                )}
                            </>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={selected.length === 0}
                            onClick={async () => {
                                await save();
                            }}
                        >
                            Speichern
                        </Button>
                        <Button
                            onClick={() => {
                                setSelected([]);
                                onClose();
                            }}
                        >
                            Schließen
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}
