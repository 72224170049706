import { Button } from "@mui/material";
import useAuthentication from "authentication/useAuthentication";
import { UserRoles } from "Models/User";
import { Link as RouterLink, useMatch } from "react-router-dom";

export default function NavigationLogistik() {
    const { hasRole } = useAuthentication();

    const isLogistikRoute = useMatch("/logistik/*");
    const isLager = useMatch("/logistik/lager");
    const isHistorie = useMatch("/logistik/historie/*");
    const isAnforderungsBearbeitung = useMatch("/logistik/anforderungsbearbeitung/*");

    return (
        <>
            {isLogistikRoute && hasRole(UserRoles.KOMMISIONIERER, UserRoles.KOORDINATOR) && (
                <>
                    <Button
                        component={RouterLink}
                        to={"/logistik/anforderungsbearbeitung"}
                        className={isAnforderungsBearbeitung ? "activeLink" : ""}
                        sx={{ my: 2, color: "white", display: "block" }}
                    >
                        Abrufbearbeitung
                    </Button>
                    <Button component={RouterLink} to={"/logistik/historie"} className={isHistorie ? "activeLink" : ""} sx={{ my: 2, color: "white", display: "block" }}>
                        Abrufhistorie
                    </Button>
                </>
            )}
            {isLogistikRoute && hasRole(UserRoles.KOORDINATOR) && (
                <Button component={RouterLink} to="/logistik/lager" className={isLager ? "activeLink" : ""} sx={{ my: 2, color: "white", display: "block" }}>
                    Lager
                </Button>
            )}
        </>
    );
}
