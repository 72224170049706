import { Firma } from "./Firma";
import { Lager } from "./Lager";
import { AnforderungsPosition } from "./AnforderungsPosition";
import { Kommissionierung } from "./Kommissionierung";
import Storno from "./Storno";
import Kommissionierer from "./Kommissionierer";

export enum BearbeitungsStatus {
    Offen = "Offen",
    InKommissionierung = "InKommissionierung",
    Versendet = "Versendet",
    Abgeschlossen = "Abgeschlossen",
    Teillieferung = "Teillieferung",
}

export enum AnforderungsTyp {
    Unbekannt = "Unbekannt",
    SW = "Wandler",
    SG = "SMGW",
    SZ = "Zähler",
    SS = "FRE",
}

export interface Anforderung {
    id: string;
    nachlieferung: boolean;
    planungsjahr: string;
    bestellerEmail: string;
    logistikBemerkung: string;
    bearbeitungsStatus: BearbeitungsStatus;
    anforderungsTyp: AnforderungsTyp;
    positionen: AnforderungsPosition[];
    kommissionierungen: Kommissionierung[];
    stornierungen: Storno[];
    anforderer: Firma;
    anfordererBemerkung: string;
    lager: Lager;
    bestellnummer: string;
    createdAt: Date;
    dueDate: Date;
    kommissionierer: Kommissionierer[];
}
