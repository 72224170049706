import Storno from "@/Models/Storno";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import DateDisplay from "./DateDisplay";

type StornosViewProps = {
    stornos: Storno[];
};
export default function StornosView(props: StornosViewProps) {
    const { stornos } = props;

    return (
        <>
            <hr />
            <h3>Stornos</h3>
            {stornos.length === 0 && <p>Keine Stornos vorhanden</p>}
            {stornos.length > 0 && <StornosTable stornos={stornos} />}
        </>
    );
}

function StornosTable(props: StornosViewProps) {
    const { stornos } = props;

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Datum</TableCell>
                    <TableCell>Artikel</TableCell>
                    <TableCell>Anzahl</TableCell>
                    <TableCell>Grund</TableCell>
                    <TableCell>Storniert von</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {stornos.map((storno, idx) => {
                    return (
                        <TableRow key={idx}>
                            <TableCell key={`${idx}_date`}>
                                <DateDisplay date={storno.createdAt} showTime={true} />
                            </TableCell>
                            <TableCell key={`${idx}_name`}>{storno.anforderungsPosition.artikel.name}</TableCell>
                            <TableCell key={`${idx}_anzahl`}>{storno.stornierteAnzahl}</TableCell>
                            <TableCell key={`${idx}_grund`}>{storno.stornierungsgrund}</TableCell>
                            <TableCell key={`${idx}_von`}>{storno.storniertVon}</TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
}
