import User, { UserTypes } from "../../../Models/User";
import useGet from "../../hooks/useGet";
import { LinearProgress, Tooltip, Typography } from "@mui/material";
import ErrorDisplay from "../../components/ErrorDisplay";
import { DataGridPro, GridActionsCellItem, GridColDef, GridRowParams } from "@mui/x-data-grid-pro";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import UpdateUser from "./UpdateUser";
import { useState } from "react";
import { Firma } from "@/Models/Firma";
import CustomGridToolbar from "../../../layout/CustomGridToolbar";
import DeleteUserDialog from "./DeleteUserDialog";

export type UserManagementProps = {};
export default function UserManagement() {
    const { data, error, isLoading, load } = useGet<User>("angelo/admin/users");
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const [userToDelete, setUserToDelete] = useState<User | null>(null);

    const { data: firmen, error: errorFirmen, isLoading: isLoadingFirmen } = useGet<Firma>("/angelo/admin/firmen"); // load firmen

    const compareStrings = (a: string, b: string): number => {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
    };

    const columns: GridColDef<User>[] = [
        { field: "id", headerName: "id", width: 80 },
        { field: "displayName", headerName: "Name", width: 180 },
        { field: "email", headerName: "Email", width: 200 },
        {
            field: "roles",
            headerName: "Rollen",
            width: 400,
            valueGetter: (value: string[]) => value.toSorted(compareStrings).join(", "),
        },
        {
            field: "firmenAnforderer",
            headerName: "Firma (Anforderer)",
            width: 250,
            valueGetter: (value: string[]) => {
                let namen = firmen?.filter((f) => value.includes(f.id.toString())).map((f) => f.name);
                return namen?.join(", ");
            },
        },
        {
            field: "type",
            headerName: "Typ",
            width: 150,
            valueGetter: (value: string) => (value === UserTypes.INTERN ? "Intern" : "eingeladen"),
        },
        {
            field: "actions",
            type: "actions",
            headerName: "",
            getActions: (params: GridRowParams<User>) => {
                return [
                    <GridActionsCellItem
                        onClick={() => setSelectedUser(params.row)}
                        key={"edit" + params.id}
                        icon={
                            <Tooltip title="Bearbeiten" placement="right">
                                <EditIcon />
                            </Tooltip>
                        }
                        label="bearbeiten"
                        showInMenu={false}
                    />,
                    <GridActionsCellItem
                        onClick={() => setUserToDelete(params.row)}
                        key={"delete" + params.id}
                        showInMenu={false}
                        label="löschen"
                        icon={
                            <Tooltip title="löschen" placement="right">
                                <DeleteIcon color={params.row.type === UserTypes.INTERN ? "warning" : "action"} />
                            </Tooltip>
                        }
                    />,
                ];
            },
        },
    ];

    return (
        <div className="contentDiv">
            <Typography variant="h1">Benutzerverwaltung</Typography>
            {(isLoading || isLoadingFirmen) && <LinearProgress />}
            {error && <ErrorDisplay title="Fehler beim Laden der Benutzer" error={error} />}
            {errorFirmen && <ErrorDisplay title="Fehler beim Laden der Firmen" error={errorFirmen} />}
            {data && firmen && (
                <DataGridPro
                    density="compact"
                    columns={columns}
                    rows={data}
                    slots={{
                        toolbar: CustomGridToolbar as any,
                    }}
                ></DataGridPro>
            )}
            {selectedUser && firmen && (
                <UpdateUser
                    user={selectedUser}
                    firmen={firmen}
                    onChanged={() => {
                        load();
                    }}
                    onClosed={() => setSelectedUser(null)}
                />
            )}
            {userToDelete && (
                <DeleteUserDialog
                    user={userToDelete}
                    onChanged={() => {
                        load();
                    }}
                    onClosed={() => setUserToDelete(null)}
                />
            )}
        </div>
    );
}
