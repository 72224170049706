import { Firma } from "./Firma";
import { LagerBestand } from "./LagerBestand";

export interface Lager {
    id: number;
    name: string;
    nummer: string;
    anforderer: Firma;
    lagerbestand: LagerBestand[];
}

export const LAGERNUMMER_RUECKLOGISTIK = "lager-ruecklogistik";
export const LAGERNUMMER_ZENTRALLAGER = "lager-zentral";
export const LAGERNUMMER_QS = "lager-qs";
