import { Button } from "@mui/material";
import Header from "layout/Header";
import useAuthentication from "./useAuthentication";
import Navigation from "layout/Navigation";

export default function SecuredPageNoRoles() {
    const { logout } = useAuthentication();

    return (
        <>
            <Header />
            <Navigation />
            <div className="contentDiv">
                Du hast nicht die erforderlichen Rollen, um Angelo zu nutzen!
                <p>Bitte melde dich bei deinem Ansprechpartner von Angelo.</p>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        logout();
                    }}
                >
                    Neu anmelden
                </Button>
            </div>
        </>
    );
}
