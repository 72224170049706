import "./index.css";
import Home, { LoginScreen } from "./Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { ThemeProvider } from "@mui/material";
import { AppContextProvider } from "./context/AppContext";
import NetzeBW_Theme from "./layout/NetzeBW_Theme";
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";
import msalInstance2 from "./authentication/msalInstance";
import SecuredPage from "./authentication/SecuredPage";
import SecuredPageLogistik from "./authentication/SecuredPageLogistik";
import SecuredPageAdministrator from "./authentication/SecuredPageAdministrator";

import RoutesAdministration from "applications/administration/RoutesAdministration";
import RoutesBestellung from "applications/bestellung/RoutesBestellung";
import RoutesLogistik from "applications/logistik/RoutesLogistik";

export default function App() {
    return (
        <ThemeProvider theme={NetzeBW_Theme}>
            <MsalProvider instance={msalInstance2}>
                <UnauthenticatedTemplate>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/*" element={<LoginScreen />} />
                        </Routes>
                    </BrowserRouter>
                </UnauthenticatedTemplate>
                <AuthenticatedTemplate>
                    <AppContextProvider>
                        <BrowserRouter>
                            <Routes>
                                <Route path="/" element={<Home />} />
                                <Route
                                    path="bestellung/*"
                                    element={
                                        <SecuredPage>
                                            <RoutesBestellung />
                                        </SecuredPage>
                                    }
                                />

                                <Route
                                    path="logistik/*"
                                    element={
                                        <SecuredPageLogistik>
                                            <RoutesLogistik />
                                        </SecuredPageLogistik>
                                    }
                                />

                                <Route
                                    path="administration/*"
                                    element={
                                        <SecuredPageAdministrator>
                                            <RoutesAdministration />
                                        </SecuredPageAdministrator>
                                    }
                                />
                            </Routes>
                        </BrowserRouter>
                    </AppContextProvider>
                </AuthenticatedTemplate>
            </MsalProvider>
        </ThemeProvider>
    );
}
