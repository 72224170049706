type InventoryImport = {
    id: string;
    typ: InventoryImportModelType;
    createdAt: Date;
    finishedAt: Date;
    status: ImportStatus;
    importError: string;
    amount: number;
    amountImported: number;
    amountFailed: number;
};

export default InventoryImport;

export enum ImportStatus {
    INPROGRESS = "INPROGRESS",
    SUCCESS = "SUCCESS",
    FAILURE = "FAILURE",
}

export enum InventoryImportModelType {
    LAGER_RUECKLOGISTIK = "LAGER_RUECKLOGISTIK",
    LAGER_BECK = "LAGER_BECK", // TODO: This can be removed, when Beck is gone from InventoryImport
    LAGER_QS = "LAGER_QS",
    LAGER_ZENTRAL = "LAGER_ZENTRAL",
    LAGER_DEZENTRAL = "LAGER_DEZENTRAL",
    MONTAGEAUFTRAEGE = "MONTAGEAUFTRAEGE",
    LAGERNAMEN = "LAGERNAMEN",
}
