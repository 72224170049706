import { Box } from "@mui/material";
import useGetFiltered, { defaultPagination } from "applications/hooks/useGetFiltered";
import { HistoryAnforderungsFilter } from "applications/logistik/anforderungen/AnforderungsFilter/AnfoderungsFilter";
import useAuthentication from "authentication/useAuthentication";
import { Anforderung } from "Models/Anforderung";
import { useEffect, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { AnforderungsSelectionBesteller } from "../OffeneAnforderungen/AnforderungsSelectionBesteller";
import { DisplayAnforderungBesteller } from "../OffeneAnforderungen/DisplayAnforderungBesteller";
import ErrorDisplay from "applications/components/ErrorDisplay";

const FIRMA_URL = "/angelo/firmen/{firmaId}/anforderungen";

export default function AnforderungsHistorie() {
    const { firmaIdAnforderer } = useAuthentication();
    const { data, error, isLoading, load, setFilter, setSort, setPagination } = useGetFiltered<Anforderung[]>(
        FIRMA_URL.replace("{firmaId}", firmaIdAnforderer),
        HistoryAnforderungsFilter,
        defaultPagination
    );
    const { bestellnummer } = useParams();

    const [selectedAnforderung, setSelectedAnforderung] = useState<string>();

    const navigate = useNavigate();

    // callback when Anforderung is changed - reselection of Anforderung by 'bestellnummer'.
    const selectedAnforderungChanged = (id: string) => {
        let anf = data.results.find((a) => a.id === id);
        if (anf) {
            let path = generatePath("/bestellung/anforderungshistorie/:bestellnummer", {
                bestellnummer: anf.bestellnummer,
            });
            navigate(path);
        }
    };

    // Page is loaded, eighet with mennuItemClick, or with passed 'bestellnummer' in path.
    useEffect(() => {
        if (data && data.results.length > 0) {
            // data is loaded
            if (bestellnummer) {
                // bestellnummer from path - setSelectedANforderung accordingly
                let newSelAnforderungid = data.results.find((a) => a.bestellnummer?.toString() === bestellnummer)?.id;
                if (newSelAnforderungid && newSelAnforderungid !== selectedAnforderung) {
                    setSelectedAnforderung(newSelAnforderungid);
                }
            } else {
                // no bestellnummer - select first Anforderung.
                setSelectedAnforderung(data.results[0].id);
            }
        }
    }, [data, bestellnummer]);

    return (
        <div className="contentDiv">
            {error && <ErrorDisplay title="Ein Fehler ist aufgetreten." error={error} />}
            <Box
                sx={{
                    flexGrow: 1,
                    display: { md: "flex" },
                }}
            >
                <AnforderungsSelectionBesteller
                    anforderungen={data.results}
                    anforderungenInTotal={data.count}
                    loading={isLoading}
                    preSelected={selectedAnforderung}
                    selectAnforderungCallBack={(id: string) => {
                        selectedAnforderungChanged(id);
                    }}
                    filterChangeCallback={(phrase) => {
                        const filter = { ...HistoryAnforderungsFilter };
                        filter.searchphrase = phrase;
                        setFilter(filter);
                    }}
                    sortChangedCallback={(sortitem) => {
                        setSort(sortitem);
                    }}
                    paginationChangedCallback={(pagination) => {
                        setPagination(pagination);
                    }}
                />
                {selectedAnforderung && (
                    <div
                        style={{
                            width: "100%",
                            border: "1px solid rgba(224, 224, 224, 1)",
                            borderRadius: "4px",
                            marginLeft: "5px",
                            padding: "0 15px 15px 15px",
                        }}
                    >
                        <DisplayAnforderungBesteller
                            loading={isLoading}
                            id={selectedAnforderung}
                            anforderungChangedCallback={() => {
                                load();
                            }}
                        />
                    </div>
                )}
            </Box>
        </div>
    );
}
