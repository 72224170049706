import { useState } from "react";
import axiosClient from "../../ApiClient";

export default function useGetBlob(route: string) {
    const [data, setData] = useState<string | ArrayBuffer | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    const load = async () => {
        setIsLoading(true);
        setError("");
        setData(null);
        try {
            const response = await axiosClient.get(route, {
                responseType: "blob",
            });

            if (response.status === 200) {
                let reader = new window.FileReader();
                reader.readAsDataURL(response.data);
                reader.onloadend = function () {
                    let base64data = reader.result;
                    setData(base64data);
                };
            }
        } catch (error: any) {
            setError(error.message + " " + error.response?.data);
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    };

    return { data, isLoading, error, load };
}
