import { Navigate, Route, Routes } from "react-router-dom";
import ArtikelPage from "./Artikel/ArtikelPage";
import Warenkorb from "./Warenkorb/Warenkorb";
import OffeneAnforderungen from "./OffeneAnforderungen/OffeneAnforderungen";
import Anforderungshistorie from "./Anforderungshistorie/Anforderungshistorie";
import MeineFirma from "./MeineFirma/MeineFirma";
import LagerBestand from "./LagerInformation/LagerBestand";

export default function RoutesBestellung() {
    return (
        <Routes>
            <Route path="/artikel" element={<ArtikelPage />} />
            <Route path="/warenkorb" element={<Warenkorb />} />

            <Route path={"/offeneAnforderungen/:bestellnummer"} element={<OffeneAnforderungen />} />
            <Route path={"/offeneAnforderungen"} element={<OffeneAnforderungen />} />

            <Route path={"/anforderungshistorie/"} element={<Anforderungshistorie />} />
            <Route path={"/anforderungshistorie/:bestellnummer"} element={<Anforderungshistorie />} />

            <Route path="/meineFirma" element={<MeineFirma />} />
            <Route path="/lagerbestand" element={<LagerBestand />} />

            <Route path="/*" element={<Navigate to="artikel" />} />
        </Routes>
    );
}
